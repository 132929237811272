<!--
 * @Descripttion:
 * @version:
 * @Author: 张天鹏
 * @Date: 2020-01-02 17:34:31
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-07-06 20:08:44
 -->
<template>
  <div class="mainbody">
    <div class="messagelist">
      <div class="message">
        <div class="message-top">
          <img src="@/assets/jhs/img/tou.png"
               alt="">
          <p>投保信息</p>
        </div>
        <div class="message-bom">
          <md-field>
            <md-input-item title="年龄"
                           class="require"
                           type="digit"
                           clearable
                           name="age"
                           v-validate="'required'"
                           :error="errors.first('age')"
                           v-model="from.customer.pidage"
                           maxlength="2"
                           placeholder="请填写真实年龄"
                           align="right"></md-input-item>
            <md-field-item title="性别"
                           align="right">
              <md-radio-group v-model="from.customer.pidsex">
                <md-radio-box name="男"
                              :style="{'color':theme,'border-color':theme}">男</md-radio-box>
                <md-radio-box name="女"
                              :style="{'color':theme,'border-color':theme}">女</md-radio-box>
              </md-radio-group>
            </md-field-item>
            <md-input-item ref="input11"
                           title="保险金额"
                           class="require"
                           type="digit"
                           clearable
                           name="lamount"
                           v-validate="'required'"
                           :error="errors.first('lamount')"
                           v-model="from.customer.lamount"
                           placeholder="请填写保险金额(万元)"
                           align="right">
              <span slot="right">万元</span>
            </md-input-item>
            <!-- <md-field-item title="缴费年限"
                           v-model="from.customer.yearnum"
                           :content="from.customer.yearnum"
                           @click="showSelector"
                           arrow
                           align="right" /> -->
          </md-field>
          <md-selector v-model="isSelectorShow"
                       :data="isSelectorShowdata"
                       max-height="320px"
                       title="请选择缴费年限"
                       large-radius
                       @choose="onSelectorChoose"></md-selector>
        </div>
        <!-- 123 -->
        <div>
          <div v-for="(item,index) of prodlist"
               :key="index">
            <div class="message">
              <div class="message-top">
                <img src="@/assets/jhs/img/zhu.png"
                     alt="">
                <p>{{item.prodname}}</p>
              </div>
              <md-field>
                <div class="message-bom">
                  <div class="message-bompd">
                    <div v-for="(ieffdata,ieff) of item.effinfo"
                         :key="ieff">
                      <md-field-item :title="ieffdata.name"
                                     :content="ieffdata.value"
                                     @click="showieffdata(ieffdata,index,ieff)"
                                     arrow="arrow-right"
                                     align="right"
                                     :solid="false" />
                    </div>
                  </div>
                  <md-field-item title="缴费年限"
                                 v-model="item.yearnum"
                                 :content="item.yearnum"
                                 @click="showSelector(item.yearlist,index)"
                                 arrow
                                 align="right" />
                </div>
              </md-field>
            </div>
          </div>
        </div>
        <!-- 影响因子 -->
        <md-selector v-model="isieffdata"
                     default-value="1"
                     :data="ieffdata.data"
                     max-height="30vh"
                     min-height="30vh"
                     title="请选择"
                     large-radius
                     @choose="onSelectieff($event,ieffdataindex,ieffdatai)"></md-selector>
        <!-- 123 -->
      </div>
    </div>
    <div class="messagebom">
      <md-button type="primary"
                 @click="comparergdata"
                 :loading="loading"
                 :style="{'backgroundColor':theme}"
                 :inactive="inactive">生成报告</md-button>
    </div>
  </div>
</template>
<script>
import { comparergsum, getdbeff } from '@/api/jhs/productlist/index'
import { isAndroid, getStorage } from '@/lib/util'
import { Toast } from 'mand-mobile'
export default {
  data () {
    return {
      isieffdata: false,
      ieffdatai: '',
      ieffdata: [], // 主险区间
      ieffdataindex: '', // 主险对应坐标
      loading: false,
      inactive: false,
      from: {
        customer: {
          pidage: '30', // 被保人年龄
          pidsex: '男', // 被保人性别
          lamount: '1'
        }
      },
      nxdata: {},
      prodlist: [],
      isSelectorShow: false,
      isSelectorShowdata: [],
      xzindex: ''
    }
  },
  watch: {
    '$route.name' (nVal) {
      const judgedr = navigator.userAgent.indexOf('dongrui')
      if (isAndroid() && judgedr != -1) {
        window.JYTitleInterface.openPage()
      }
    }
  },
  created () {
    this.theme = getStorage('theme', '')
    this.loading = true
    this.inactive = true
    getdbeff({ prods: this.$route.query.cpcodes, type: 'CP' }).then(res => {
      this.prodlist = res.data.data.prodyear
      let efflist = res.data.data.efflist

      this.prodlist.forEach((item, index) => {
        this.$set(item, 'yearnum', item.yearlist[0].text)
        efflist.forEach((item1, index1) => {
          if (item1.prodcode == item.prodcode) {
            this.$set(item, 'effinfo', item1.effinfo)
          }
        })
      })
      this.prodlist.forEach(v => {
        for (let item in v.effinfo) {
          v.effinfo[item].value = v.effinfo[item].data[0].value
        }
      })
      console.log(this.prodlist)
      this.loading = false
      this.inactive = false
    })
  },
  methods: {
    comparergdata () { // 生成报告
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loading = true
          this.inactive = true
          this.from.cpcodes = this.$route.query.cpcodes
          this.from.prodlist = this.prodlist
          this.prodlist.forEach((item, index) => {
            // this.$set(this.form, item.prodcode, item.yearnum)
            let key1 = item.prodcode
            this.nxdata[key1] = item.yearnum
          })
          console.log(this.nxdata)
          comparergsum({ ...this.from, ... this.nxdata }).then(res => {
            this.loading = false
            this.inactive = false
            this.$router.push({
              path: '/generate',
              query: { jhsno: res.data.data, fromwhere: this.$route.query.fromwhere ? this.$route.query.fromwhere : '' }
            })
          }).finally(() => {
            this.loading = false
            this.inactive = false
          })
        } else {
          Toast.failed('请把信息填写完整!')
        }
      })
    },
    showSelector (data, index) {
      this.isSelectorShow = true
      this.xzindex = index
      this.isSelectorShowdata = data
    },
    onSelectorChoose ({ text }) {
      this.prodlist[this.xzindex].yearnum = text
    },
    showieffdata (item, index, i) {
      this.ieffdata = item
      this.ieffdataindex = index
      this.ieffdatai = i
      this.isieffdata = true
    },
    onSelectieff ({ label }, index, i) {
      this.prodlist[index].effinfo[i].value = label
    }
  }
}
</script>
<style lang="stylus" scoped>
.messagelist {
  width: 90vw;
  padding-bottom: 10vh;
  margin: 0 auto;
  background-color: #F7F6FB;
}

.message {
  background: #fff;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-radius: 5px;
}

.message-top {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eee;
  position: relative;
}

.message-top img {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  left: 50px;
}

.message-top .md-agree {
  display: table-cell;
  vertical-align: middle;
  padding: 20px 0 20px 30px;
}

.message-top p {
  width: 100%;
  padding: 20px 0 20px 130px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.message-top .md-agree {
  float: right;
}

.messagebom {
  width: 100%;
  height: 6vh;
  position: fixed;
  bottom: 0;
  display: flex;
  background: #fff;
  z-index: 5;
}

.messagebom .md-button.block {
  height: 100%;
}

.md-radio-group .md-check-base-box {
  width: 120px;
}

/deep/ .md-selector .md-radio-item.is-selected .md-cell-item-title {
  color: #111a34;
}
/deep/ .md-field-item-title{
  font-size 0.35rem
}
/deep/ .md-field-item-control{
  font-size 0.35rem
}
</style>
